import { loadFromLocalStorage, saveToLocalStorage } from '@databases/localStorage';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { contantAuthentication, constantExchangeRate } from '@contants/index';
import { showError } from '@components/notification';

// Context.
import { AuthContext } from './Authcontext';

// Service.
import { signIn, resetPassword, Registered } from '@page/Auth/requests';
import { getMe } from '@utils/urls';
import { apiGetDetailCurrency } from '@services/ManageFee/FeeServices';

const AuthProvider = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = React.useState(false);
  const [user, setUser] = React.useState(
    loadFromLocalStorage(contantAuthentication.USER_DATA) || null
  );
  const [token, setToken] = React.useState(loadFromLocalStorage(contantAuthentication.DATA_AUTH));
  const fetchExchangeRate = async () => {
    try {
      const exchangeRate = await apiGetDetailCurrency({ currency_code: 'JPY' });
      saveToLocalStorage(
        constantExchangeRate.EXCHANGE_RATE,
        JSON.stringify(exchangeRate?.data?.data)
      );
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (isLogin) {
      const origin = location.state?.from?.pathname || '/customer/dashboard';
      navigate(origin);
    }
  }, [isLogin]);

  const handleLogin = async (username, password) => {
    const response = await signIn(username, password);
    if ([200, 201].includes(response?.status)) {
      if (response?.data.status.code === 'success') {
        const tokenUser = JSON.stringify(response?.data.data);
        setToken(tokenUser);
        saveToLocalStorage(contantAuthentication.DATA_AUTH, tokenUser);
        getMe().then((res) => {
          if (res.status === 200) {
            saveToLocalStorage(contantAuthentication.USER_DATA, JSON.stringify(res.data.data));
            setTimeout(() => {
              setIsLogin(true);
              navigate(
                res.data.data?.roles?.length === 1 && res.data.data?.roles?.includes('CUSTOMER')
                  ? '/customer/dashboard'
                  : '/customer/dashboard'
              );
            });
          }
        });
      } else {
        showError(response?.data.status.message);
      }
    }
  };

  const handleLogout = async () => {
    // Call after Logout Success.
    // const response = await signOut();
    // if (response.status === 200) {
    setToken('');
    saveToLocalStorage(contantAuthentication.DATA_AUTH, '');
    saveToLocalStorage('searchHistory', []);
    setIsLogin(false);
    navigate('/account/signin');
    // }
  };

  const handleResetPassword = async () => {
    // Call after Logout Success.
    // const response = await resetPassword();
    // if (response.status === 200) {
    setToken('');
    saveToLocalStorage(contantAuthentication.DATA_AUTH, '');
    setIsLogin(false);

    navigate('/account/signin');
    // }
  };

  const handleRegister = async (values) => {
    console.log(values);
    // Call after Logout Success.
    // const response = await Registered();
    // if (response.status === 200) {
    setToken('');
    saveToLocalStorage(contantAuthentication.DATA_AUTH, '');
    setIsLogin(false);
    navigate('/account/signin');
    // }
  };
  const updateAvatar = (data) => {
    if (data) {
      const updatedUser = { ...data };
      setUser(updatedUser);
      saveToLocalStorage(contantAuthentication.USER_DATA, JSON.stringify(updatedUser));
    }
  };

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    resetPassword: handleResetPassword,
    register: handleRegister,
    fetchExchangeRate: fetchExchangeRate,
    updateAvatar: updateAvatar,
    user
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
